<template>
    <div id="projectsEdit">
        <div>
            <b-row>
                <b-col cols="12">
                    <projects-form-edit :project="project"></projects-form-edit>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import ProjectsFormEdit from './components/ProjectsFormEdit'

export default{
    name: 'projectsEdit',
    components: {ProjectsFormEdit},
    props:['project'],
    data() {
        return {

        }
    },
}
</script>